.day {
    display: inline-block;
    margin-left: 60px;
    margin-top: 40px;
    padding: 10px 40px;

    > p { 
        color: #fff;
        font-family: "Raleway Light";

        strong { 
            font-family: "Raleway ExtraBold";
            font-size: 24px;
        }
        sup { 
            font-size: 12px; 
            vertical-align: top;
            line-height: 14px;
        }
    }
}