$dark: #000;
$grey: #e6e6e6;

$green: #abd377 !default;
$orange: #fbb254 !default;
$pink: #ed3e81 !default;
$purple: #73509c !default;
$magenta: #9431a2 !default;

$primary_font_color: #6d7a83 !default;
$secondary_font_color: #0d1d31 !default;