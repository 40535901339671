#time {
    margin-top: 2px;
    
    > .container-fluid { @include gradient-g1; }
    
    .time-inner {
        text-align: center;
        display: inline-block;
        padding: 45px 0 30px 0;
        @include relative-h-center;

        > div { 
            display: inline-block;
            float: left;
            width: 70px;

            > p, > span {
                float: left;
                width: 100%;
                text-align: center;
                text-transform: uppercase;
            }
            
            > span {
                font-family: "Roboto Medium";
                font-size: 50px;
                line-height: 60px;
            }
            
            > p {
                font-family: "Roboto Black";
                font-size: 12px;
            }
        }

        > span {
            float: left;
            width: 6px;
            height: 6px;
            background: $primary_font_color;
            border-radius: 3px;
            margin: 25px 0;
        }
    }
}