@font-face {
    font-family: 'Raleway Light';
    src:url('fonts/Raleway/Raleway-Light.eot');
    src:url('fonts/Raleway/Raleway-Light.eot#iefix') format('embedded-opentype'),
        url('fonts/Raleway/Raleway-Light.ttf') format('truetype'),
        url('fonts/Raleway/Raleway-Light.woff2') format('woff'),
        url('fonts/Raleway/Raleway-Light.woff') format('woff'),
        url('fonts/Raleway/Raleway-Light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway ExtraLight';
    src:url('fonts/Raleway/Raleway-ExtraLight.eot');
    src:url('fonts/Raleway/Raleway-ExtraLight.eot#iefix') format('embedded-opentype'),
        url('fonts/Raleway/Raleway-ExtraLight.ttf') format('truetype'),
        url('fonts/Raleway/Raleway-ExtraLight.woff2') format('woff'),
        url('fonts/Raleway/Raleway-ExtraLight.woff') format('woff'),
        url('fonts/Raleway/Raleway-ExtraLight.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway Regular';
    src:url('fonts/Raleway/Raleway-Regular.eot');
    src:url('fonts/Raleway/Raleway-Regular.eot#iefix') format('embedded-opentype'),
        url('fonts/Raleway/Raleway-Regular.ttf') format('truetype'),
        url('fonts/Raleway/Raleway-Regular.woff2') format('woff'),
        url('fonts/Raleway/Raleway-Regular.woff') format('woff'),
        url('fonts/Raleway/Raleway-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway Medium';
    src:url('fonts/Raleway/Raleway-Medium.eot');
    src:url('fonts/Raleway/Raleway-Medium.eot#iefix') format('embedded-opentype'),
        url('fonts/Raleway/Raleway-Medium.ttf') format('truetype'),
        url('fonts/Raleway/Raleway-Medium.woff2') format('woff'),
        url('fonts/Raleway/Raleway-Medium.woff') format('woff'),
        url('fonts/Raleway/Raleway-Medium.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway Bold';
    src:url('fonts/Raleway/Raleway-Bold.eot');
    src:url('fonts/Raleway/Raleway-Bold.eot#iefix') format('embedded-opentype'),
        url('fonts/Raleway/Raleway-Bold.ttf') format('truetype'),
        url('fonts/Raleway/Raleway-Bold.woff2') format('woff'),
        url('fonts/Raleway/Raleway-Bold.woff') format('woff'),
        url('fonts/Raleway/Raleway-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway ExtraBold';
    src:url('fonts/Raleway/Raleway-ExtraBold.eot');
    src:url('fonts/Raleway/Raleway-ExtraBold.eot#iefix') format('embedded-opentype'),
        url('fonts/Raleway/Raleway-ExtraBold.ttf') format('truetype'),
        url('fonts/Raleway/Raleway-ExtraBold.woff2') format('woff'),
        url('fonts/Raleway/Raleway-ExtraBold.woff') format('woff'),
        url('fonts/Raleway/Raleway-ExtraBold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway SemiBold';
    src:url('fonts/Raleway/Raleway-SemiBold.eot');
    src:url('fonts/Raleway/Raleway-SemiBold.eot#iefix') format('embedded-opentype'),
        url('fonts/Raleway/Raleway-SemiBold.ttf') format('truetype'),
        url('fonts/Raleway/Raleway-SemiBold.woff2') format('woff'),
        url('fonts/Raleway/Raleway-SemiBold.woff') format('woff'),
        url('fonts/Raleway/Raleway-SemiBold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway Heavy';
    src:url('fonts/Raleway/Raleway-Heavy.eot');
    src:url('fonts/Raleway/Raleway-Heavy.eot#iefix') format('embedded-opentype'),
        url('fonts/Raleway/Raleway-Heavy.ttf') format('truetype'),
        url('fonts/Raleway/Raleway-Heavy.woff2') format('woff'),
        url('fonts/Raleway/Raleway-Heavy.woff') format('woff'),
        url('fonts/Raleway/Raleway-Heavy.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway Thin';
    src:url('fonts/Raleway/Raleway-Thin.eot');
    src:url('fonts/Raleway/Raleway-Thin.eot#iefix') format('embedded-opentype'),
        url('fonts/Raleway/Raleway-Thin.ttf') format('truetype'),
        url('fonts/Raleway/Raleway-Thin.woff2') format('woff'),
        url('fonts/Raleway/Raleway-Thin.woff') format('woff'),
        url('fonts/Raleway/Raleway-Thin.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}