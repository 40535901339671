@import "reset";

html { font-size: 62.5%; }
body {
    background-color: white;
    font-family: "Raleway Normal", Helvetica;
    font-size: 18px;
    line-height: 22px;
    color: $primary_font_color;
    background: url('../resources/images/background.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
}
h1, h2, h3, h4, h5, h6 { 
    font-family: "Raleway ExtraBold", Helvetica;
    font-size: 24px;
}
a, a:hover, a:focus, a:active { text-decoration: none; }
ul { list-style: none; }
figure { margin: 0; }
img { max-width: 100%; }
strong { font-family: "Raleway Bold", Helvetica; }
::-moz-focus-inner { border: 0; }

@import "forms";