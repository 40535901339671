@import "home-carousel";
@import "video-teaser";
@import "home-articles-list";
@import "countdown";
@import "teaser";
@import "days";
@import "agenda-details";
@import "location-details";

#home-teaser {
    background: $grey;
    padding: 35px 0;
}

#agenda-teaser {
    padding: 90px 50px 70px 50px;
    background: #fff;
    
    .teaser-text * { text-align: center; line-height: 24px; }
    .day {
        float: left;
        padding: 10px 30px;
        text-align: center;
        width: calc(33% - 40px);
        margin-left: 0;
        
        + .day { margin-left: 60px; }
    }
}

#location-teaser {
    padding: 75px 60px 60px 60px;
    background: #fff;
    
    .teaser-text * { text-align: center; line-height: 24px; }
    .day {
        float: left;
        padding: 10px 30px;
        text-align: center;
        width: calc(33% - 40px);
        margin-left: 0;
        margin-top: 0;
        
        + .day { margin-left: 60px; }
    }
}