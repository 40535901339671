.dropdown.hover-toggle:hover > .dropdown-menu {
    display: block;
    margin-top: 0;
    padding: 0;
    border: 0;
}

.navbar-default {
    border-radius: 0;
    border: none;
    background: transparent;
}

.navbar-brand { height: auto; }

.carousel-indicators li { 
    border-width: 2px;
    width: 12px;
    height: 12px;
    position: relative;
    
    &.active { top: -1px; }
}

.btn-default {
    text-transform: uppercase;
    color: #fff !important;
    font-size: 24px;
    line-height: 60px;
    padding: 0 60px;
    border: none;
    border-radius: 0;
}