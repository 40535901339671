#location-details {
    background: #fff;
    margin-bottom: 240px;
    
    > article {
        display: inline-block;
        padding: 20px 0 0 0;
        
        > figure {
            float: left;
            position: relative;
            margin: 0 70px 35px 0;
            
            > figcaption {
                position: absolute;
                top: 220px;
                left: 30px;
                font-size: 30px;
                line-height: 34px;
                font-family: "Raleway ExtraBold";
                text-shadow: 8px 8px 5px rgba(0, 0, 0, 0.7);
                color: #fff;
                
                > span { 
                    float: left;
                    padding-top: 10px;
                    font-size: 24px;
                    font-family: "Raleway Light"; 
                    text-shadow: none;
                }
            }
        }
        p { 
            color: $dark;
            text-align: justify;
            
            .info-line { 
                float: left;
                width: 100%;
                margin-bottom: 8px;
                
                > span {
                    float: left; 
                    width: 120px;
                }
                strong { float: left; width: 480px; }
            }
            
            .horizontal-tab { display: inline-block; width: 25px; }
            
            + p { margin-top: 20px; }
            &.info-paragraph {
                float: right;
                width: 600px;
            }
        }
    }
    
    .day { 
        display: block; 
        margin-left: 0;
        padding: 10px;
        float: left;
        width: 100%;
        
        &:first-child { margin-top: 0; }
    }
}