@font-face {
    font-family: 'Roboto Thin';
    src:url('fonts/Roboto/Roboto-Thin.eot');
    src:url('fonts/Roboto/Roboto-Thin.eot#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-Thin.ttf') format('truetype'),
        url('fonts/Roboto/Roboto-Thin.woff2') format('woff'),
        url('fonts/Roboto/Roboto-Thin.woff') format('woff'),
        url('fonts/Roboto/Roboto-Thin.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto ThinItalic';
    src:url('fonts/Roboto/Roboto-ThinItalic.eot');
    src:url('fonts/Roboto/Roboto-ThinItalic.eot#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype'),
        url('fonts/Roboto/Roboto-ThinItalic.woff2') format('woff'),
        url('fonts/Roboto/Roboto-ThinItalic.woff') format('woff'),
        url('fonts/Roboto/Roboto-ThinItalic.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Light';
    src:url('fonts/Roboto/Roboto-Light.eot');
    src:url('fonts/Roboto/Roboto-Light.eot#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-Light.ttf') format('truetype'),
        url('fonts/Roboto/Roboto-Light.woff2') format('woff'),
        url('fonts/Roboto/Roboto-Light.woff') format('woff'),
        url('fonts/Roboto/Roboto-Light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto LightItalic';
    src:url('fonts/Roboto/Roboto-LightItalic.eot');
    src:url('fonts/Roboto/Roboto-LightItalic.eot#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-LightItalic.ttf') format('truetype'),
        url('fonts/Roboto/Roboto-LightItalic.woff2') format('woff'),
        url('fonts/Roboto/Roboto-LightItalic.woff') format('woff'),
        url('fonts/Roboto/Roboto-LightItalic.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Regular';
    src:url('fonts/Roboto/Roboto-Regular.eot');
    src:url('fonts/Roboto/Roboto-Regular.eot#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-Regular.ttf') format('truetype'),
        url('fonts/Roboto/Roboto-Regular.woff2') format('woff'),
        url('fonts/Roboto/Roboto-Regular.woff') format('woff'),
        url('fonts/Roboto/Roboto-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto RegularItalic';
    src:url('fonts/Roboto/Roboto-RegularItalic.eot');
    src:url('fonts/Roboto/Roboto-RegularItalic.eot#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-RegularItalic.ttf') format('truetype'),
        url('fonts/Roboto/Roboto-RegularItalic.woff2') format('woff'),
        url('fonts/Roboto/Roboto-RegularItalic.woff') format('woff'),
        url('fonts/Roboto/Roboto-RegularItalic.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Medium';
    src:url('fonts/Roboto/Roboto-Medium.eot');
    src:url('fonts/Roboto/Roboto-Medium.eot#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-Medium.ttf') format('truetype'),
        url('fonts/Roboto/Roboto-Medium.woff2') format('woff'),
        url('fonts/Roboto/Roboto-Medium.woff') format('woff'),
        url('fonts/Roboto/Roboto-Medium.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto MediumItalic';
    src:url('fonts/Roboto/Roboto-MediumItalic.eot');
    src:url('fonts/Roboto/Roboto-MediumItalic.eot#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype'),
        url('fonts/Roboto/Roboto-MediumItalic.woff2') format('woff'),
        url('fonts/Roboto/Roboto-MediumItalic.woff') format('woff'),
        url('fonts/Roboto/Roboto-MediumItalic.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Bold';
    src:url('fonts/Roboto/Roboto-Bold.eot');
    src:url('fonts/Roboto/Roboto-Bold.eot#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-Bold.ttf') format('truetype'),
        url('fonts/Roboto/Roboto-Bold.woff2') format('woff'),
        url('fonts/Roboto/Roboto-Bold.woff') format('woff'),
        url('fonts/Roboto/Roboto-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto BoldItalic';
    src:url('fonts/Roboto/Roboto-BoldItalic.eot');
    src:url('fonts/Roboto/Roboto-BoldItalic.eot#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype'),
        url('fonts/Roboto/Roboto-BoldItalic.woff2') format('woff'),
        url('fonts/Roboto/Roboto-BoldItalic.woff') format('woff'),
        url('fonts/Roboto/Roboto-BoldItalic.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Black';
    src:url('fonts/Roboto/Roboto-Black.eot');
    src:url('fonts/Roboto/Roboto-Black.eot#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-Black.ttf') format('truetype'),
        url('fonts/Roboto/Roboto-Black.woff2') format('woff'),
        url('fonts/Roboto/Roboto-Black.woff') format('woff'),
        url('fonts/Roboto/Roboto-Black.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto BlackItalic';
    src:url('fonts/Roboto/Roboto-BlackItalic.eot');
    src:url('fonts/Roboto/Roboto-BlackItalic.eot#iefix') format('embedded-opentype'),
        url('fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype'),
        url('fonts/Roboto/Roboto-BlackItalic.woff2') format('woff'),
        url('fonts/Roboto/Roboto-BlackItalic.woff') format('woff'),
        url('fonts/Roboto/Roboto-BlackItalic.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}