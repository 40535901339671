body.login {
    background: url('../resources/images/login.jpg');
    background-position: top center;
    background-size: cover;
    
    .page-wrap, 
    .main-content { height: 100%; }
}

.login-wrapper {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    
    .first-line { line-height: 72px; }
    .second-line { line-height: 140px; }
    .third-line { line-height: 64px; }
}

#login-form {
    @include relative-h-center;
    width: 400px;
    margin-top: 30px;
    
    > label { 
        color: #fff; 
        text-transform: uppercase;
        width: 100%;
        
        > input[type="checkbox"] {
            margin: 0 0 0 7px;
        }
        > span {
            float: right;
            height: 36px;
            line-height: 36px;
            background: rgba($purple, 0.7);
            padding: 0 10px;
            width: 157px;
            overflow: hidden;
        }
        
        &.remember-me { 
            @include relative-h-center;
            text-align: right; 
            width: 200px;
            cursor: pointer;
        }
    }
    
    input[type="submit"] { width: 200px; text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3); }
}