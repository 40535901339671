.teaser {
    .teaser-text {
        text-align: justify;
        
        .teaser-title {
            color: $purple;
            text-transform: uppercase;
            margin: 0 0 20px 0;
            
            > span {
                font-family: "Raleway Light";
                color: $primary_font_color;
                text-transform: none;
            }
        }
        p + p { 
            margin: 20px 0 0 0;
            
            strong {
                color: $purple;
                display: inline; 
                
                + strong { margin-left: 30px; }
            }
        }
    }
}