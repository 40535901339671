@mixin relative-h-center {
    position: relative;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}

@mixin relative-v-center {
    position: relative;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

@mixin relative-center {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin gradient-g1 {
    background-image: -webkit-gradient(
        linear, center top, center bottom, from(rgba(230, 230, 230, 1)),
        to(rgba(255, 255, 255, 0)), color-stop(.2, rgba(240, 240, 240, 0.15))
    );
}