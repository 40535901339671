@media (max-width: $breakpoint-small + 1) {
    
    .hero-banner .carousel-caption .first-line,
    .login-wrapper .first-line,
    .first-line { font-size: 30px; line-height: 36px; }
    .hero-banner .carousel-caption .second-line, 
    .login-wrapper .second-line,
    .second-line { font-size: 48px !important; line-height: 54px; }
    .hero-banner .carousel-caption .third-line, 
    .login-wrapper .third-line,
    .third-line { font-size: 30px; line-height: 36px; }
    
    #time .time-inner {
        padding: 24px 0;
        
        div {
            width: 70px;
            
            > p { font-size: 12px; }
        }
    }
    
    .container { 
        padding-left: 15px !important; 
        padding-right: 15px !important;
    }
    
    #agenda-details .daily-agenda {
        padding-right: 0;
        padding-left: 0;
    }
    
    #agenda-details,
    #location-details,
    #home-articles-list {
        margin-bottom: 60px;
    }
    
    #location-teaser,
    #agenda-teaser {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    
    #location-details > article { 
        float: left; 
        width: 100%;
        
        &:nth-of-type(2) {
            > figure > figcaption { top: 190px; }
        }
        &:last-of-type > p:last-of-type + .btn { width: 100%; }
    }
    
    .site-footer .footer-logo {
        display: none;
        
        + [class^='col-'] {
            width: 100%;
            
            p { margin-top: 20px; }
            .footer-social-links > li {
                line-height: 35px;
                height: 35px;
                
                [class^='icon-'] { font-size: 26px; }
            }
        }
    }
    
    #home-articles-list > article > .article-image { padding-right: 0; }
    .teaser .teaser-text p + p strong + strong { margin-left: 10px; }
}

@media (max-width: 414px) {
    #location-details > article > figure > figcaption { line-height: 24px; top: 200px; font-size: 20px; > span { font-size: 18px; padding-top: 0; } }
    #location-details > article:nth-of-type(2) > figure > figcaption { top: 150px; }
    
    .carousel-caption { padding: 20px 0; }
    
    .hero-banner .carousel-caption .first-line,
    .first-line { font-size: 26px; line-height: 26px; }
    .hero-banner .carousel-caption .second-line, 
    .login-wrapper .second-line,
    .second-line { font-size: 40px !important; line-height: 40px; }
    .hero-banner .carousel-caption .third-line, 
    .third-line { font-size: 26px; line-height: 26px; }
    
    #home-articles-list > article > .article-image { 
        
        + [class^='col-md-'] { padding-left: 0; }
    }
    
    #home-articles-list > article {
        h3 { line-height: 24px; }
        > .article-image {
            height: auto;
            
            > img { width: 100%; height: auto; transform: none; -webkit-transform: none; } 
        }
    }
    h1, h2, h3, h4, h5, h6 { font-size: 20px; }
    
    #login-form { width: 100%; padding: 0 15px; }
}

@media (max-width: 375px) {
    #location-details > article > figure > figcaption { top: 180px; }
    #location-details > article:nth-of-type(2) > figure > figcaption { top: 120px; }
    
    .teaser .teaser-text p + p strong { float: left; clear: both; margin-left: 0 !important; }
    
    .site-footer .footer-social-links > li { margin-left: 15px; }
    
    
    #time > .container-fluid {
        padding: 0 !important;
        
        .time-inner div {
            width: calc(25% - 5px);
            > span { font-size: 36px; line-height: 40px; }
        }
    }
}

@media (max-width: 340px) {
    /*.hero-banner .carousel-caption .first-line,
    .first-line { font-size: 20px; line-height: 20px; }
    .hero-banner .carousel-caption .second-line, 
    .login-wrapper .second-line,
    .second-line { font-size: 28px !important; line-height: 28px; }
    .hero-banner .carousel-caption .third-line, 
    .third-line { font-size: 20px; line-height: 20px; }*/
    .hero-banner .carousel-caption .first-line { font-size: 20px; }
    .carousel-caption { bottom: 62%; }
    .hero-banner .carousel-caption { bottom: 50%; }
    
    
    .navbar-default .navbar-brand { width: 200px; }
    .navbar-default .navbar-toggle { margin-top: 15px; }
    
    #agenda-teaser .day { padding-left: 20px; padding-right: 20px; }
    
    #location-details > article > figure > figcaption { left: 10px; top: auto; bottom: 20px; }
    #location-details > article:nth-of-type(2) > figure > figcaption { top: auto; bottom: 20px; }
    
    #location-details > article:last-of-type > p:last-of-type + .btn { padding-left: 0; padding-right: 0; }
}