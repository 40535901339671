label { 
    font-size: 18px; 
    margin: 15px 0 0 0;
    line-height: 36px;
}

input[type="text"], 
input[type="email"], 
input[type="password"] {
    padding: 0 10px;
    background: #fff;
    font-size: 18px;
    border: 1px solid $grey;
    display: block;
    width: 100%;
    border-radius: 0;
    border-bottom-right-radius: 16px;
    color: $dark;
    height: 42px;
}

input:-webkit-autofill {
    background-color: #fff !important;
}

input[type="checkbox"] {
    float: left;
    width: 36px;
    height: 36px;
    display: inline-block;
    margin: 0 10px 0 0;
    appearance: none;
    -webkit-appearance: none;
    background: #fff;
    color: #fff;
    border-radius: 0;
    border: none;
    position: relative;
    cursor: pointer;
    
    &:checked:after { 
        font-family: "Glyphicons Halflings";
        content: "\e013";
        font-size: 24px;
        color: $dark;
        position: absolute;
        left: 5px;
        top: 5px;
    }
}
textarea {
    padding: 5px 10px;
    background: #fff;
    font-size: 12px;
    border: 1px solid $grey;
    resize: none;
    display: block;
    width: 100%;
}

input[type="file"] { display: none; }

input[type="submit"] {
    border: 0;
    border-radius: 0;
    outline: #fff;
    margin: 30px 0 0 0;
    padding: 0 45px;
    cursor: pointer;
    border-radius: 0;
    font-size: 24px;
    line-height: 50px;
    height: 50px;
    background: $purple;
    font-family: "Raleway Bold";
    color: #fff;
    letter-spacing: 2px;
    width: auto;
    @include relative-h-center;
}