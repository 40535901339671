#home-carousel {
    .carousel-indicators { bottom: 10px; }
}

.line {
   color: #fff;
   text-align: center; 
}
.first-line {
    font-size: 72px;
    font-family: "Gotham SSm Book";
    line-height: 98px;
    @extend .line;
}
.second-line {
    font-size: 140px;
    line-height: 160px;
    @extend .line;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    strong { font-family: "Raleway ExtraBold"; }
}
.third-line {
    font-size: 64px;
    font-family: "Gotham SSm Thin";
    line-height: 90px;
    @extend .line;
}

.carousel-caption {
    transform: translateY(50%);
    bottom: 50%;
    padding: 30px 0;
    left: 10%;
    right: 10%;
}

.hero-banner .carousel-caption {
    transform: translateY(50%);

    .first-line {
        font-size: 60px;
    }
    .second-line {
        font-size: 120px;
        line-height: 140px;
        strong { font-family: "Gotham SSm Black"; }
    }
}