.site-footer {
    height: 180px;
    background: url("../resources/images/footer.jpg");
    background-size: cover;
    padding-right: 130px;
    
    * { color: #fff !important; }
    
    .footer-logo {
        height: 180px;
        
        > img {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    
    p { 
        margin: 60px 0 30px 0; 
        font-size: 16px;
        text-align: right;
    }
    
    .footer-social-links {
        > li { 
            float: left; 
            margin-left: 25px; 
            width: 25px; 
            height: 25px;
            line-height: 25px;
        
            a { float: left; }
            &:first-child { width: auto; font-size: 16px; }
            &:hover i { 
                display: block;
                font-size: 24px; 
                transition: all .1s ease;
                margin: -2px 0 0 -2px;
            }
        }
    }
}