@import "main-menu";
@import "footer";

.green { color: $green; }
.orange { color: $orange; }
.pink { color: $pink; }
.purple { color: $purple; }

.background-green { background-color: $green !important; }
.background-orange { background-color: $orange !important; }
.background-pink { background-color: $pink !important; }
.background-purple { background-color: $purple !important; }