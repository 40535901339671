@media (max-width: 1279px) {

}

@media (max-width: 1200px) {
    .container { width: 100%; }
    
    #location-details > article p.info-paragraph { width: auto; max-width: calc(100% - 570px); }
    #location-details > article p .info-line strong { max-width: 100%; }
}

@media (max-width: 1150px) {
    .navbar-default .navbar-brand { width: 300px; }
    .navbar-default #main-menu .navbar-nav li { margin-left: 80px; }
}

@media (max-width: 1133px) {
    #location-details > article > p > .btn { float: left !important; clear: both; }
}