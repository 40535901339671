.navbar-default {
    margin: 30px 0;
    
    #main-menu {
        padding: 0;
        
        .navbar-nav {
            background: #fff;
            padding: 10px 0;
            margin-top: 30px;
            
            li {
                margin-left: 120px;
                
                > a { 
                    font-family: "Raleway ExtraBold";
                    text-transform: uppercase;
                    color: $purple;
                    padding: 0 15px;
                    background: #fff !important;
                }
                
                &:hover > a, &.active > a { color: $pink; }
                &:first-child { }
                &:last-child { }
                
                &.hover-toggle > .dropdown-menu { }
                &.hover-toggle > .dropdown-menu > li { }
                &.hover-toggle > .dropdown-menu > li > a { }
                &.hover-toggle > .dropdown-menu > li > .dropdown-menu { }
            }
        }
    }
}