#home-articles-list {
    margin-top: 60px;
    margin-bottom: 140px;
    background-color: #fff;
    
    > article {
        display: block;
        width: 100%;
        float: left;
        padding: 24px 0;
        border-top: 2px solid $secondary_font_color;
        
        > .article-image {
            height: 230px;
            padding: 0 55px 0 0;
            
            > img {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                max-height: 100%;
                width: 100%;
            }
        }
        
        h3 {
            color: $secondary_font-color;
            text-transform: uppercase;
            line-height: 24px;
            margin: 12px 0 10px 0;
            
            &.two-lines {
                margin-top: 0;
            }
        }
        
        p + p { margin-top: 20px; }
        
        .link-to-full-article { 
            display: inline-block; 
            margin: 16px 0 0 0;
            color: $primary_font_color;
            
            &:hover { color: $secondary_font_color; }
        }
        
        &:last-child {
            border-bottom: 2px solid $secondary_font_color;
        }
    }
}