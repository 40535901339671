@import "colors";
@import "mixins";

// Breakpoints
$breakpoint-big: 1200px;
$breakpoint-medium: 768px;
$breakpoint-small: 479px;

// Bootstrap variables
// xs is for resolutions between 320px and 768px
// sm and md is for resolutions between 768px and 1366px
// lg is for resolutions larger than 1366px
$screen-xs: 320px;
$screen-sm: 768px;
$screen-md: $screen-sm;
$screen-lg: 1280px;
$screen-sm-max: ($screen-lg - 1);
$screen-md-max: $screen-sm-max;
$container-desktop: 1200px;
$container-large-desktop: $container-desktop;
$container-tablet: 100%;
$container-md: $container-tablet;

//Grid Variables
$container-width: 1200px !default;
$total-columns: 12 !default;
$column-width: 100 / $total-columns !default; // calculates individual column width based off of # of columns
$column-margin: 4% !default; // space between columns