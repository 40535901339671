// Reset
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em,
font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td {
	margin: 0; padding: 0; border: 0; outline: 0;
	font-weight: inherit; font-style: inherit; font-size: 100%; font-family: inherit; vertical-align: baseline;
}
* { font-size:100%; outline: none;}
html, body { height: 100%; margin: 0; }
:focus { outline: 0; }
a { outline: none; }
ul, ol { list-style-position: inside; }
table { border-collapse: separate; border-spacing: 0; }
caption, th, td { text-align: left; font-weight: normal; line-height: 1.8em; vertical-align: top; }
*, *:after, *:before { outline: 0 none !important; }