@media (max-width: 1000px) {
    .navbar-default #main-menu .navbar-nav li { margin-left: 30px; }
}

@media (max-width: 860px) {
    .hero-banner .carousel-caption .first-line, 
    .first-line { font-size: 48px; line-height: 60px; }
    .hero-banner .carousel-caption .second-line, 
    .login-wrapper .second-line,
    .second-line { font-size: 100px !important; line-height: 100px; }
    .third-line { font-size: 54px; line-height: 72px; }
    
    .navbar-default .navbar-brand { width: 250px; }
    .navbar-default #main-menu .navbar-nav { margin-top: 20px; }
}

@media (max-width: 800px) {
    #agenda-details .daily-agenda > li .da-headline { width: 100%; }
    .navbar-default #main-menu .navbar-nav li { margin-left: 20px; }
}

@media (max-width: $breakpoint-medium) {
    body {
        font-size: 16px;
        line-height: 20px;
    }
    
    #location-details > article > figure {
        width: 100%;
        margin-right: 0;
    }
    #location-details > article p.info-paragraph {
        width: 100%;
        max-width: 100%;
    }
    
    [class^='col-md-'] + [class^='col-md-'] {
        margin-top: 20px;
    }
    
    #home-articles-list > article > .article-image > img { width: auto; }
    
    .navbar-default #main-menu .navbar-nav { 
        float: left !important;
        
        > li { margin-top: 10px; }
    }
    
    #time .time-inner {
        div {
            width: 100px;
            
            > p { font-size: 16px; }
        }
    }
    
    #location-details > article:last-of-type > p:last-of-type { 
        line-height: inherit !important; 
        float: left;
        
        .btn { display: none; }
        + .btn { display: block !important; margin-top: 10px; }
    }
    
    .teaser .day { 
        width: 100% !important; 
        margin-left: 0 !important;
        
        +.day { margin-top: 16px !important; }
    }
    
    #home-teaser [class^='col-md-'] { width: 100%; }
    #home-articles-list > article > .article-image { 
        padding-right: 16px;
    }
    
    .navbar-default .navbar-toggle { margin-top: 25px; }
    .navbar-default #main-menu .navbar-nav li { margin-left: 12px; &:first-child { margin-left: 0; }}
}

@media (max-width: 767px) {
    .navbar-default #main-menu .navbar-nav li { margin-left: 0; }
}