#agenda-details {
    background: #fff;
    margin-bottom: 240px;

    .day { 
        display: block; 
        margin-left: 0;
        padding: 10px;
        float: left;
        width: 100%;

        &:first-child { margin-top: 0; }
    }

    .daily-agenda {
        float: left;
        width: 100%;
        padding: 40px 70px 20px 0;

        > li {
            float: left;
            width: 100%;

            .da-hours { 
                float: left;
                color: #111;
                width: 170px;
            }
            .da-headline {
                text-transform: uppercase;
                color: $dark;
                width: 800px;
                float: left;

                > .glyphicon { 
                    float: right;
                    color: $purple;
                    cursor: pointer;
                }
                
                &.expandable {
                    color: $purple;
                    font-family: "Raleway Bold";
                }
            }
            .da-description {
                padding: 0;
                float: left;
                width: 100%;
                
                .da-desc-inner {
                    padding: 15px 0;
                    color: #111;
                    font-family: "Raleway Bold";

                    a { text-decoration: underline; }
                    > ul { padding-left: 30px; }
                }
            }

            + li { margin-top: 15px; }
        }
    }
}